<template>
  <div class="list">
    <div class="warn-input">
      <el-input placeholder="请输入内容" v-model="value">
        <el-button type="primary" slot="append" icon="el-icon-search" @click="searchAlarm"></el-button>
      </el-input>
    </div>
    <div class="warn-list">
      <div class="warn-short" v-for="item in warn_data_list" :key="item.pk" @click="sendInformations(item)">
        <el-card shadow="hover" :class="item.show ? 'active':''">
          <el-row :gutter="20">
            <el-col :span="14">
              <i class="el-icon-message-solid"></i> <span>{{ item.fields.warnningType }}</span>
            </el-col>
            <el-col :span="10">
              <el-button style="float: right;margin-right: 10px" type="text">{{ item.fields.datetime }}</el-button>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :span="14">
              <div class="grid-content bg-purple">{{ item.fields.address }}，{{ item.fields.warnningType }}。</div>
            </el-col>
          </el-row>
        </el-card>
      </div>
    </div>
    <div class="warn-pagination">
      <el-pagination
          small
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage"
          :page-size="10"
          layout="total, prev, pager, next"
          :total="paginationNum">
      </el-pagination>
    </div>
  </div>
</template>
<script>
import {handled, queryAlarmCCAll, delAlarm} from '../../api/requests.js'
import {EventBus} from "../store/bus.js";

export default {
  name: "WarnEventList",
  data() {
    return {
      value: '',
      cardStyle: true,
      currentPage: 1,
      paginationNum: 10,
      warn_data: [],
      warn_data_list: [],
    }
  },
  methods: {
    handleCurrentChange(val) {
      this.warn_data_list = this.warn_data.slice((val - 1) * 10, val * 10)
      this.sendInformations(this.warn_data_list[0])
    },
    searchAlarm() {
      let warnData = this.warn_data
      let searchData = []
      console.log(this.value)
      warnData.forEach(res => {
        if (JSON.stringify(res.fields).match(this.value) != null) {
          searchData.push(res)
        }
      })
      this.warn_data_list = searchData.slice(0, 10)
      this.paginationNum = Object.keys(searchData).length
      this.sendInformations(this.warn_data_list[0])

    },
    handledAlarm(item) {
      let user = localStorage.getItem('Authorization')
      this.warn_data_list.forEach(res => {
        if (res === item) {
          res.fields.handled = '1' // 已处理
        }
      })
      let param = {'info': user, 'alarm': JSON.stringify(item)}
      handled(param).then(res => {
        console.log(res)
      })
    },
    deleteAlarm(item) {
      let user = localStorage.getItem('Authorization')
      let param = {'info': user, 'alarm': JSON.stringify(item)}
      delAlarm(param).then(res => {
        console.log(res)
        this.getAlarmData()
      })
    },
    sendInformations(item) {
      // 改变选中
      this.warn_data_list.forEach(res => res['show'] = false)
      item.show = !item.show
      EventBus.$emit('Informations', item)
    },
    getAlarmData() {
      let param = this.$route.query // url参数
      queryAlarmCCAll(param).then(res => {
        let factoryTypeDefault = {}
        res.data.factory.forEach(res => {
          factoryTypeDefault[res.pk] = res.fields.factoryName
        })
        res.data.info.forEach(res => {
          res['show'] = false
          res['factory'] = factoryTypeDefault[res.fields.factory]
          let date = new Date(res.fields.datetime);
          res.fields.datetime = this.$moment(date).format('YYYY-MM-DD HH:mm:ss')
          res.fields.createTime = null
        })
        this.warn_data = res.data.info
        this.warn_data_list = res.data.info.slice(0, 10)
        this.paginationNum = Object.keys(res.data.info).length
        if (res.data.info.length <= 0) {
          this.sendInformations({'fields': ''})
          return
        }
        this.sendInformations(this.warn_data_list[0])
      })
    },
  },
  mounted() {
    this.getAlarmData()
  },
  watch: {
    $route() {
      //监听router变化 选中aside-item
      this.getAlarmData()
    },
  }
}
</script>

<style scoped>

.warn-list {
  margin-top: 15px;
  height: 70vh;
  overflow: auto;
}

.warn-pagination {
  margin-top: 15px;
  margin-bottom: 15px;
  float: right;
}

.active {
  color: #409EFF;
  background-color: #F5F7FA;
}
.list{
  height: 80vh;
}
</style>